@import 'Responsive';

.carousel-container {

  display: flex;
  padding-top: 70px;
  height: 65%;
  width: 100%;
}

.card-container {
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  display: flex;

  scroll-behavior: smooth;
}

.scroll-paddle {
  font-size: 85px;
  position: absolute;
  padding-top: 195px;
  z-index: 500;
  cursor: pointer;
  opacity: .3;
  transition: 0.3s;
  padding-left: 8px;

  @media only screen and (max-width: $breakpoint-phone) {
    display: none;
  }
}

.scroll-paddle:hover {
  opacity: 1;
}

.right {
  right: 13px;
}

.card-container::-webkit-scrollbar{
  display: none;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8); /* Black background with opacity */
  z-index: 600; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 22px;

  @media only screen and (max-width: $breakpoint-phone) {   
    padding-top: 22px;
  }
}

.image-overlay-container {
  height: 84%;
  width: 62%;
  background-image: url(https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: $breakpoint-phone) {   
    height: 21%;
    width: 68%;
  }
}

.close-overlay {
  font-size: 55px;
  position: absolute;
  top: 31px;
  right: 80px;
  transition: 0.3s;
}

.close-overlay:hover {
  color: rgb(207, 207, 207);
}