@import 'Responsive';

.card {
  width: 60%;
  min-width: 40%;
  min-height: 70%;
  margin-left: 50px;
  margin-bottom: 30px;
  padding-right: 50px;

  @media only screen and (max-width: $breakpoint-phone) {
    min-width: 80%;
  }
}

.card-content {
  background-color:black;
  height: 100%;
  border-radius: 10px;
  box-shadow: 3px 2px 20px black;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  cursor: pointer;
  overflow: hidden;

  .card-title {
    font-size: 54px;
    text-align: start;
    font-family: 'Bebas Neue', cursive;
    padding-left: 20px;
    background-color: #1f1d1d;
    width: 100%;
    height: 80px;
    overflow: hidden;
    z-index: 100;
  }

  .background {
    background-size: cover;
    background-position: center, center;
    background-repeat: no-repeat;
    height: 100%;
    transition: all .3s ease-in-out;
  }

  .background:hover {
    transform: scale(1.05);
  }
}