@import 'Responsive';
// #front-video {
//   object-fit: cover;
//   width: 100vw;
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
//   }

#front-video {
  // position: absolute;
  // right: 0;
  // bottom: 0;
  // min-width: 100%;
  // min-height: 100 vh;
  // transform: translateX(calc((100% - 100vw) / 2));

  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  //width: 100%;
  height: auto;   
  z-index: -1;

  }

  @media only screen and (max-width: $breakpoint-phone) {
    #front-video {
      display: none;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    #front-video {
      display: none;
    }
  }
