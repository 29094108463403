input[type=text] {
    background-color: white;
    color: black;
    height: 50px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 4px;


  }

  button {
    background-color: transparent;
    height: 50px;
    border: solid 1px white;
    border-radius: 5px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }

  button:hover {
    background-color: white;
    color: black;
    transition-duration: 0.4s;
  }

  .error {
    border: 1px solid red !important;
  }

  .error-text {
    color: red;
  }

  .main-form {
    animation: fadeInAnimation 2s;
  }

  @keyframes fadeInAnimation {
    from { opacity: 0; }
    to   { opacity: 1; }
}