@import 'Responsive';

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 400;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.menu-button {
  color: white;
  cursor: pointer;
}

#main {
  transition: margin-left .5s;
  padding: 16px;
  position: relative;
  z-index: 500;
  padding: 35px;
  display: none;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

@media only screen and (max-width: $breakpoint-phone) {
  #main {
    display: block;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  #main {
    display: block;
  }
}

.open {
  width: 250px;
}

.closed {
  width: 0px;
}

.social-media-icons {
  display: flex;
  flex-direction: row;
  padding-left: 29px;

  a {
    padding: 10px 14px 2px 2px;
  }
}
