@import 'Responsive';

 body {
  overflow-x: hidden;
}

.header-menu {
  font-family: 'Abel', cursive;
  color: rgb(223, 223, 223);
  justify-content: flex-end;
  font-size: 35px;

  display: flex;
  position: relative;
}

h1 {
  font-size: 54px;
  font-family: 'Raleway', sans-serif;

  @media only screen and (max-width: $breakpoint-phone) {
    font-size: 34px;
  }
}

.header-logo {
  position: relative;
  display: flex;
  height: 0px;
}

a {
  color: white;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: rgb(204, 204, 204);
}


.header-section {
  .album-cover {
    display: none;
  }
  display: flex;
  height: 100vh;


  //background-image: url('../assets/the-unknown.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-direction: column;
  scroll-snap-align: start;

  .call-to-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    //margin-top: 200px;
    flex-direction: column;
  
    button {
      background-color: transparent;
      border: solid 3px white;
      border-radius: 5px;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      margin-top: 20px;
    }
  
    button:hover {
      background-color: white;
      color: black;
      transition-duration: 0.4s;
    }

    @media only screen and (max-width: $breakpoint-phone) {
      img {
        height: 30%;
        position: absolute;
        top: 20vh;
      }
      // .album-cover {
      //   display: flex;
      //   margin-right: 28vh;
      // }
    }

    @media only screen and (max-width: $breakpoint-tablet) and (min-width: $breakpoint-phone) {
      img {
        height: 50%;
        position: absolute;
        top: 20vh;
      }
      // .album-cover {
      //   display: flex;
      //   margin-right: 48vh;
      // }
    }
  }

  .scroll-indicator {
    margin-top: 50px;
    color: white;
    font-size: 74px;
    animation: bounce 3s infinite;
    animation-timing-function: ease;
  }

  @media screen and (max-width: $breakpoint-phone) {
    background-image: url('../assets/mobile-bg.png');
  }

  @media screen and (max-width: $breakpoint-tablet) {
    background-image: url('../assets/mobile-bg.png');
  }
}

.horizontal-scroll-indicator {
  font-size: 30px;
  font-family: 'Abel';
  font-style: italic;
  margin-top: 26px;

  animation: headShake 3s infinite;
  animation-timing-function: ease;

  .title {
    padding: 20px;
  }
}

.album-image {
  height: 25px;
  position: absolute;
  top: 111px;
}



.flex-child {
  flex: 1;
}

.main-band-pic {
  width: 100%;
  height: 50%;
}

#voidco-logo {
  width: 70px; 
  height: 70px;
  padding: 20px;
  padding-left: 40px;
}

.menu-item {
  margin: 50px;
}

@media only screen and (max-width: $breakpoint-phone) {
  .menu-item {
    display: none;
  }

  .header-menu {
    font-size: 44px;
  }

  #voidco-logo {
    width: 50px; 
    height: 70px;
    padding: 13px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .menu-item {
    display: none;
  }

  .header-menu {
    font-size: 44px;
  }

  #voidco-logo {
    width: 50px; 
    height: 70px;
    padding: 13px;
    padding-left: 20px;
  }
}



.section {
  background-size: cover;
  background-position:top;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  scroll-snap-align: start;
  overflow-x: hidden;
}

.signup-section {
  background-color: rgb(36, 34, 34);
  justify-content: center;
  //background-image: url('../assets/band_pic.jpg');

  .email-form {
    margin-top: auto;
  }

  .footer-section {
    color: grey;
    width: auto;
    position: relative;
    margin-top: auto;
    margin-bottom: 20px;

    @media only screen and (max-width: $breakpoint-phone) {
      padding-left: 67px;
      padding-right: 67px;
      padding-bottom: 17px;
    }
  }
}

.merch-section {
  background-color: #424e54;
  justify-content: center;
  background-image: url('../assets/the-void-album-no-text-no-wear.png');
  background-position: center;
  background-blend-mode: difference;

  img {
    height: 100%;
    z-index: 0;

    @media only screen and (max-width: $breakpoint-phone) {
      height: 50%;
    }
  }

  h1 {
    position: absolute;
    z-index: 2;
    text-shadow: 2px 2px 5px black;
    justify-self: flex-end;
  }
}

.merch-link {
  transition: 0.3s;
}

.merch-link:hover {
  color: rgb(204, 204, 204);
}

.video-section {
  background-color: rgb(8, 48, 46);
  background-image: url('../assets/STARFIELD.png');
  background-attachment: fixed;
  background-position: center;
  overflow-y: hidden;

  h1 {
    z-index: 2;
    text-shadow: 2px 2px 5px black;
    justify-self: flex-start;
  }
}

.photo-section {
  background-color: rgba(255,255,255,1);
  background-image: url('../assets/forest.jpg');
  overflow-y: hidden;

  background-position: center;

  h1 {
    z-index: 2;
    text-shadow: 2px 2px 5px black;
    justify-self: flex-start;
  }

}

.desktop-icons {
  font-size: 32px;
  margin-top: 25px;
}

.social-media-icons {
  justify-content: center;
}

.carousel-container {
  height: 100%;
}

.call-to-action-header {
  font-family: 'Abel', cursive;
  color: #ff7d7d;
  justify-content: flex-end;
  font-size: 65px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-weight: 900;
  text-align: center;
  @media only screen and (max-width: $breakpoint-phone) {
    font-size: 40px;
  }
  @media only screen and (max-width: $breakpoint-tablet) {
    font-size: 40px;
  }
}


/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1f2125;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
